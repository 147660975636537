<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Relocation Calculator
      </h2>
      <img
        src="../../assets/img/tools_relocation_banner.jpg"
        alt="Legislation Banner"
        class="w-full lg:h-96 h-80 mt-4"
      >
      <br>
      <br>
      <div>
        Expatriate remuneration in has gone through
        much transformation as the living standards improved greatly in
        many developing countries. Companies need to consider from multiple
        perspectives to create enough yet not too incentive for an expatriate
        to be relocated.
        <br>
        <br>
        The AIMS Relocation Calculator takes into account the Living,
        Housing, Transportation, Medical, Education, Risk and Tax costs
        to estimate a reasonable relocation package.
      </div>
        <br>
        <br>
      <div class="flex flex-wrap justify-center lg:gap-16 gap-10">
        <img
          src="../../assets/img/tools_relocation_calculator1.png"
          alt="Expatriate Practice Banner"
          class="w-full"
        >
        <img
          src="../../assets/img/tools_relocation_calculator2.png"
          alt="Expatriate Practice Banner"
          class="w-full"
        >
      </div>
        <br>
        <br>
        <div class="mx-auto">
         Services in Relocation Calculator:
          <ul class="px-10 p-4 list-disc text-red-700">
            <li><b>Domestic relocation package</b> -
              within a country (e.g. Shanghai to Guangzhou in China,
              etc.)</li>
            <li><b>Relocation across countries</b> -
            over 10,000 cities globally.</li>
            <li><b>Simulate package</b> – unlimited as desired.</li>
          </ul>
        </div>
      <br>
      <br>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
